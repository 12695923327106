body {
  margin: 0;
  font-family: "Futura", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F1F1F1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container{
  margin: 20px auto;
  width: 60%;
}
.counter-container{
  color: black;
}
.clockdown-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.clockdown-item{
  margin: 5px 20px 5px 20px;
  width: 100px;
  font-size: 70%;
  font-weight: bold;
}
.clockdown-item2{
  margin: 5px 20px 5px 20px;
  width: 100px;
  font-size: 200%;
  font-weight: bold;
  color: #11878E;  
}
.bold{
  font-size: 120%;
}
p{
  font-weight: bold;
}
h1{
  color: #11878E;
}
p span{
  color: #11878E;
}
a {
  text-decoration: none;
  color: #11878E;  
}
